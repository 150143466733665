/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		Allen Property
		Date:		7th June 2018
		Author:		Edge Marketing Solutions

===========================================================================*/
/* [Global Variables] - http://sass-lang.com/guide */
@import url("https://fonts.googleapis.com/css?family=Quicksand:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");
/*=========================================================================*/
/* [Global Template Styles] */
body {
  color: #58595b;
}

a {
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  color: #55acee;
}

a:hover {
  color: #003663;
  text-decoration: none;
}

h1 {
  margin: 0 0 2rem 0;
  font-weight: 300;
  font-size: 3.1rem;
  color: #003663;
}

h2 {
  margin: 2rem 0;
  font-weight: 300;
  font-size: 2.3rem;
  color: #003663;
}

h3 {
  margin: 2rem 0;
  font-weight: 300;
  font-size: 2.3rem;
  color: #55acee;
}

h4 {
  margin: 2rem 0;
  font-weight: 300;
  font-size: 1.8rem;
  color: #003663;
}

h5 {
  margin: 2rem 0;
  font-weight: 300;
  font-size: 1.8rem;
  color: #55acee;
}

h5.modal-title {
  margin: 0;
}

h6 {
  margin: 2rem 0 0.5rem 0;
  font-weight: 700;
  font-size: 1.2rem;
  color: #003663;
}

img {
  max-width: 100%;
}

img.svg-responsive {
  width: 100% \9;
}

img.img-lightbox {
  cursor: zoom-in;
}

.btn {
  border-radius: 2rem;
  border: none;
  background-image: url(../images/btn-bg.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
}

.btn-primary {
  background-color: #003663;
}

.btn-primary:hover {
  background-color: #55acee;
}

.btn-outline-primary {
  border: 1px solid #003663;
  color: #003663;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
  background-color: #003663;
}

.btn-outline-info {
  border: 1px solid #55acee;
  color: #55acee;
}

.btn-outline-info:hover,
.btn-outline-info:focus {
  border: 1px solid #55acee;
  background-color: #55acee;
}

.btn-light {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.btn-light:hover,
.btn-light:focus {
  background-color: #55acee;
  color: white;
}

.btn-info {
  background-color: rgba(88, 89, 91, 0.15);
  color: #58595b;
}

.btn-info:hover,
.btn-info:focus {
  background-color: #58595b;
  color: white;
}

.btn-secondary {
  background-color: #58595b;
}

.swiper-container {
  width: 100%;
  height: 80vh;
  position: relative;
}

.swiper-container .swiper-slide img {
  width: 100%;
}

.subpage-banner img {
  width: 100%;
}

video#bgvid {
  position: fixed;
  right: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  background: url(../images/rotator.jpg) no-repeat;
  background-size: cover;
  z-index: 0;
}

svg#logo {
  width: 210px;
  height: 210px;
}

.blue-fill {
  fill: #003663;
}

.white-fill {
  fill: #FFFFFF;
}

.pen {
  fill: none;
  stroke: #003663;
  stroke-linecap: round;
}

@media (min-height: 500px) {
  .swiper-container {
    height: 55vh;
  }
}

@media (min-height: 600px) {
  .swiper-container {
    height: 60vh;
  }
}

@media (min-height: 768px) {
  .swiper-container {
    height: 65vh;
  }
}

@media (min-height: 990px) {
  .swiper-container {
    height: 70vh;
  }
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 2.6rem;
  }
  h2 {
    font-size: 2.1rem;
  }
  h3 {
    font-size: 2.1rem;
  }
  h4 {
    font-size: 1.6rem;
  }
  h5 {
    font-size: 1.6rem;
  }
  h6 {
    font-size: 1.1rem;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  h1 {
    font-size: 2.4rem;
  }
  h2 {
    font-size: 1.9rem;
  }
  h3 {
    font-size: 1.9rem;
  }
  h4 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.4rem;
  }
  h6 {
    font-size: 1.1rem;
  }
  svg#logo {
    width: 160px;
    height: 160px;
  }
  .d-xs-none {
    display: none;
  }
}

@media (max-width: 767px) and (max-width: 425px) {
  svg#logo {
    width: 140px;
    height: 140px;
  }
}

/*=========================================================================*/
/* [Header Template Styles] */
.header {
  background-color: #003663;
}

.header .h-logo {
  top: 10px;
  position: absolute;
  z-index: 10000;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .header {
    padding: 0.5rem 0;
  }
}

/*=========================================================================*/
/* [Navigation Template Styles] */
.navbar-dark {
  padding: 0;
}

.navbar-dark .navbar-nav .nav-link {
  padding: 0.5rem 1.0rem;
  color: white;
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #55acee;
}

.dropdown-item {
  padding: 0.25rem 1rem;
  text-align: center;
  color: #58595b;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #55acee;
}

/* Large desktop */
@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .navbar-dark {
    justify-content: flex-end;
  }
  .navbar-dark .navbar-nav .nav-link {
    text-align: right;
  }
  .dropdown-menu {
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .dropdown-item {
    padding: 0.25rem 1rem;
    text-align: right;
    color: rgba(255, 255, 255, 0.7);
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.7);
  }
}

/*=========================================================================*/
/* [Page Template Styles] */
.page {
  width: 100%;
  padding: 3rem 0;
}

.subpage-banner {
  width: 100%;
  position: relative;
}

.subpage-banner::after,
.swiper-slide-image::before {
  content: ' ';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(35, 31, 32, 0.25);
}

.breadcrumb {
  margin: 0 0 2rem 0;
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  font-size: 0.8rem;
  color: rgba(88, 89, 91, 0.5);
}

.breadcrumb-item,
.breadcrumb a,
.breadcrumb-item + .breadcrumb-item::before,
.breadcrumb-item.active {
  color: rgba(88, 89, 91, 0.5);
}

.sidecol h6 {
  padding-bottom: 1.2rem;
  margin: 2rem 0 1.4rem;
  border-bottom: 1px solid rgba(88, 89, 91, 0.1);
  text-transform: uppercase;
}

.sidecol .nav .nav-link {
  padding: 0.75rem 0;
  color: #58595b;
}

.sidecol .nav .active .nav-link {
  color: #55acee;
}

h1.page-title {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 1.0rem;
  border-bottom: 1px solid rgba(88, 89, 91, 0.1);
}

h1.page-title span {
  font-weight: 400;
}

.testimonial {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  background-color: #003663;
  color: white;
}

.testimonial-image-wrapper {
  flex-basis: 40%;
  position: relative;
}

.testimonial-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.testimonial-image::before {
  width: calc(100% + 3px);
  height: 100%;
  content: ' ';
  position: absolute;
  top: 0;
  left: -1px;
  background-image: url(../images/testimonial-overlay-circle.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.testimonial h1,
.testimonial h2,
.testimonial h3,
.testimonial h4,
.testimonial h5,
.testimonial h6 {
  color: white;
}

.testimonial h1 {
  font-size: 2.4rem;
}

.testimonial-content {
  flex-basis: 35%;
  padding: 4rem 0 4rem 4rem;
}

.testimonial-content h4 {
  margin-top: 0;
  color: white;
  font-size: 2.6rem;
}

.search-wrapper {
  z-index: 10;
  position: relative;
}

.search {
  padding: 1.5rem;
  margin-top: -40px;
  border-radius: 3rem;
  background-color: white;
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.7);
}

.search .form-group {
  margin-bottom: 0;
}

.search .form-control {
  border-radius: 2rem;
  background-color: rgba(88, 89, 91, 0.1);
  font-size: 1rem;
}

.card,
.card-holidays {
  flex-basis: 29.8% !important;
  flex-grow: 0 !important;
  background-color: white;
  -webkit-box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.1);
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  text-align: center;
  color: #58595b;
}

.card-holidays {
  flex-basis: 46.3% !important;
}

.card h5 {
  margin: 0 0 0.5rem 0;
  font-size: 1.4rem;
  color: #003663;
  font-weight: 500;
}

.card h5 a {
  color: #003663;
}

.card h5 a:hover {
  color: #55acee;
  text-decoration: none;
}

.card .card-details {
  padding-top: 1rem;
  margin-top: 1rem;
}

.card .card-details h5 {
  margin: 1rem 0 1.5rem;
}

.gallery-listing,
.feature-listing {
  display: block;
  height: 120px;
  overflow: hidden;
  background: none no-repeat 50% 50%;
  background-size: cover;
  margin: 5px;
}

.feature-listing {
  height: 300px;
  margin: 0;
}

.feature-listing:hover {
  opacity: 0.7;
}

.swiper-text {
  box-sizing: border-box;
  position: absolute;
  z-index: 4000;
  top: 50%;
  padding: 0 2rem;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 3rem;
  color: white;
  font-weight: 300;
}

.swiper-text .title {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
}

.read-more-content {
  height: 250px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.read-more-overlay {
  height: 100px;
  width: 100%;
  padding-top: 100px;
  margin-top: -100px;
  display: block;
  position: relative;
  z-index: 10;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 70%, white 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 70%, white 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 70%, white 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
}

.heightAuto {
  height: auto !important;
}

/* Large desktop */
@media (min-width: 1280px) {
  .testimonial-image {
    background-size: cover;
    background-position: top center;
  }
  .testimonial-image::before {
    background-size: cover;
  }
}

/* Large desktop */
/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .testimonial-image-wrapper {
    flex-basis: 40%;
  }
  .testimonial-image::before {
    -webkit-background-size: cover;
    background-size: cover;
  }
  .testimonial-content {
    flex-basis: 55%;
    padding: 4rem 0 4rem 4rem;
  }
  .testimonial-content h4 {
    margin-top: 0;
    color: white;
    font-size: 2.0rem;
  }
  .card {
    flex-basis: 45% !important;
  }
  .swiper-text {
    font-size: 2rem;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .testimonial-image-wrapper {
    flex-basis: 40%;
  }
  .testimonial-content {
    flex-basis: 55%;
    padding: 4rem 0 4rem 4rem;
  }
  .testimonial-content h4 {
    margin-top: 0;
    color: white;
    font-size: 2.0rem;
  }
  .search {
    padding: 1.0rem;
    margin-top: 1.5rem;
    border-radius: 1.5rem;
  }
  .card {
    flex-basis: 44% !important;
  }
  .swiper-text {
    font-size: 2rem;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .page {
    padding: 2rem 0.5rem;
  }
  .testimonial {
    display: block;
    padding-top: 2rem;
    text-align: center;
  }
  .testimonial-image-wrapper {
    width: 300px;
    height: 300px;
    margin: 0 auto;
  }
  .testimonial-image::before {
    -webkit-background-size: cover;
    background-size: cover;
  }
  .testimonial-content {
    flex-basis: 55%;
    padding: 2rem;
  }
  .testimonial-content h4 {
    margin-top: 0;
    color: white;
    font-size: 2.0rem;
  }
  .search {
    display: none;
    padding: 1.0rem;
    margin-top: 3rem;
    border-radius: 1.5rem;
  }
  .search-visible {
    display: block;
  }
  .card {
    flex-basis: 100% !important;
  }
  .swiper-text {
    font-size: 1.2rem;
    text-align: center;
  }
}

/*=========================================================================*/
/* [Footer Template Styles] */
.footer {
  width: 100%;
  padding: 3rem 0;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.footer .nav .nav-link {
  color: #58595b;
}

.footer .nav .nav-link:hover {
  color: #55acee;
}

.fa-facebook-square {
  color: #3d5a98;
}

.fa-linkedin-square {
  color: #007bb5;
}

.footer .f-social {
  font-size: 2.0rem;
}

.footer .footnotes,
.footer .footnotes a {
  color: rgba(88, 89, 91, 0.5);
}

.footer .nav .nav-link:first-child {
  padding-left: 0;
}

.footer-cap {
  width: 100%;
  height: 200px;
  background-color: #003663;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-image: url(../images/bg-footer.jpg);
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
  .footer .nav .nav-link {
    padding: 0.75rem 0;
  }
}

/*=========================================================================*/
/* [Table as row override Styles] */
table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

/* col-lg */
@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

/* col-md */
@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

/* col-sm */
@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

/* col-xs */
table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}

.ribbon-container {
  width: calc(100% + 20px);
  position: absolute;
  top: 410px;
  left: -10px;
  z-index: 100;
}

.ribbon-container .ribbon {
  width: 100%;
  background: #003663;
  color: white;
  padding: 13px 0;
  font-size: 18px;
  display: block;
  font-weight: bold;
  position: relative;
  z-index: 10;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.33);
}

.ribbon-container .triangle-l,
.ribbon-container .triangle-r {
  border-style: solid;
  border-width: 10px;
  height: 0;
  width: 0;
  position: absolute;
  top: -10px;
  z-index: 0;
}

.ribbon-container .triangle-l {
  border-color: transparent #001a30 transparent transparent;
  left: -10px;
}

.ribbon-container .triangle-r {
  border-color: transparent transparent transparent #001a30;
  right: -10px;
}

.modal-dialog {
  overflow: hidden;
}

.quote {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
  background: url("/images/quote-bg.jpg") no-repeat center center;
  background-size: cover;
  color: white;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  padding: 20vh 60px;
}

.quote:before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ' ';
  background: rgba(0, 0, 0, 0.5);
}

.quote .quote-text:before {
  content: ' ';
  width: 68px;
  height: 56px;
  position: absolute;
  top: -56px;
  left: -68px;
  background: url("/images/quote-open.svg") center center no-repeat;
  background-size: cover;
}

.quote .quote-text:after {
  content: ' ';
  width: 68px;
  height: 56px;
  position: absolute;
  bottom: -56px;
  right: -68px;
  background: url("/images/quote-close.svg") center center no-repeat;
  background-size: cover;
}

@media (max-width: 768px) {
  .quote {
    color: white;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    padding: 20vh 34px;
  }
  .quote .quote-text:before {
    width: 34px;
    height: 28px;
    top: -28px;
    left: -34px;
  }
  .quote .quote-text:after {
    width: 34px;
    height: 28px;
    bottom: -28px;
    right: -34px;
  }
}

.front-testimonial {
  position: relative;
  display: block;
  background: #F2F2F2;
  width: 100%;
  height: auto;
  padding: 10vh 0;
}

.testimonial-rotator .swiper-container {
  width: 100%;
  height: auto !important;
  position: relative;
}

.testimonial-rotator .swiper-container .swiper-slide img {
  width: 160px !important;
}

.testimonial-rotator .swiper-pagination-bullet {
  display: block;
  margin-bottom: 17px;
  width: 17px;
  height: 17px;
}

.testimonial-rotator .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #003663 !important;
}

.testimonial-rotator .swiper-slide .media {
  font-family: "Lato", sans-serif;
  color: #8892A0;
  font-size: 22px;
  font-weight: 300;
}

.testimonial-rotator .swiper-slide .media h5 {
  color: #8892A0;
  font-size: 23px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .testimonial-rotator .swiper-pagination-bullet {
    display: inline-block;
    margin-right: 17px;
  }
}
